/* eslint-disable global-require */
import React, { useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import marked from '@/utils/markedRenderer';

import { Helmet } from 'react-helmet';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box } from '@latitude/box';
import HowToApply from '@latitude/how-to-apply';
import Section from '@latitude/section';
import { List, ListItem } from '@latitude/list';
import { Strong, Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Heading4, Heading5 } from '@latitude/heading';
import {
  ALIGN,
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { ImportantInformation } from '@latitude/important-information';
import SvgInline from '@latitude/svg-inline';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import footerData from './data/footer.json';
import imgHeroBanner from './images/hero-insurances.webp';
import DegreesCardMicrositeHeader from './_28d-microsite-header';

import calloutLeftBg from './images/confetti_left.webp';
import calloutRightBg from './images/confetti_right.webp';
import DontHaveACardSection from './_dont-have-card';

const DATA1 = [
  {
    icon: 'icon2-hand-thumbs-up',
    title: 'Pay like a local. Online or overseas.',
    text:
      'Save with no international transaction or currency conversion fees on purchases and great foreign exchange rates set by Mastercard so you get foreign currencies at the same rate we do. T&Cs, card fee and other charges apply.'
  },
  {
    icon: 'icon2l-global-shopping',
    title: 'Shop and be rewarded with the Latitude Rewards Program',
    text:
      'Enjoy up to $120 worth of Latitude Rewards each year which you can redeem for e-gift cards from participating retailers when you spend $1,000 or more on eligible transactions in every statement period for 12 months<sup>1</sup>.<br /><a href="/credit-cards/28-degrees/latitude-rewards/">Learn more</a>'
  },
  {
    icon: 'icon2-gift',
    title: 'Amazing everyday and travel offers',
    text:
      'Enjoy a range of credit back offers, discounts and deals from big brands at home and abroad, like <a href="https://specials.priceless.com/en-au/cardhome/28%C2%B0_Global_Platinum_Mastercard?issuerId=201810190038&productId=201904150013" rel="noopener noreferrer" target="_blank">Mastercard Priceless Specials</a>, Booking.com, Expedia and Wotif. Exclusions and T&Cs apply.<br /><a href="/travelpartners/">Learn more</a>'
  }
];
const DATA2 = [
  {
    icon: 'icon2-globe-blue',
    title: 'Stay in touch with free Global Data Roaming',
    text:
      '3GB free data each calendar year & 15% off any extra data purchased in over 150 countries with Flexiroam. T&Cs apply.<br /><a href="/credit-cards/28-degrees/global-data-roaming/">Learn more</a>'
  },
  {
    icon: 'icon2-travel-alt-pink',
    title: 'Travel with ease with free Flight Delay Pass',
    text:
      'Food, drinks and free Wi-Fi in a selection of airport lounges if your flight is delayed 2+ hours<sup>4</sup>.<br /><a href="/credit-cards/28-degrees/flight-delay-pass/">Learn more</a>'
  },
  {
    icon: 'icon2-relationship',
    title: 'Share the love with an additional cardholder at no extra fee',
    text:
      'Share all the features and benefits without needing to open a second account.<br /><a href="/credit-cards/28-degrees/addcard/">Add an additional cardholder</a>'
  }
];
const RESPONSIVE_SETTINGS = [
  {
    breakpoint: 10000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  }
];

const InsurancesPage = props => {
  const featureSliderItems = [
    {
      icon: 'icon2l-shield-tick',
      title: 'Complimentary Purchase Protection Insurance',
      text:
        'Cover for the loss of purchases due to accidental damage or theft &mdash; up to $1,500<sup>2</sup>.'
    },
    {
      icon: 'icon2l-global-shopping',
      title: 'Complimentary E-Commerce Protection Insurance',
      text:
        'Cover for the non-delivery and/or incomplete delivery of online purchases &mdash; up to $1,500<sup>3</sup>.'
    }
  ];

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    promoBannerData: [],
    featureSliderData: [
      {
        heading: 'Why choose a Latitude 28° Global?',
        featureCards: featureSliderItems
      }
    ]
  });

  useEffect(() => {
    let [featureSliders, heroBanners, promoBanners] = [[], [], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || '')),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        case CONTENTFUL_COMPONENTS.PROMO_BANNER:
          promoBanners = [...promoBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      promoBannerData:
        promoBanners.length > 0 ? promoBanners : state.promoBannerData,
      featureSliderData:
        featureSliders.length > 0 ? featureSliders : state.featureSliderData
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout customFooter={footerData} location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 10px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }

          [class^='HowToApply__StyledConfetti'],
          [class^='RatesListBranded__RatesList-']::before,
          [class^='RatesListBranded__RatesList-']::after {
            display: none;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au/credit-cards/28-degrees/`}
          />
        </Helmet>
        <DegreesCardMicrositeHeader />

        <PageHeaderSection
          title={'Complimentary protection for your shopping'}
          subTitle={
            <>
              With a Latitude 28&deg; Global Platinum Mastercard credit card you
              can shop knowing we've got your back if anything goes wrong.
            </>
          }
          pageImage={imgHeroBanner}
        />

        <FeaturesSlider
          css={`
            && div.lfs-card {
              max-width: 350px;
              margin: 0 auto;
            }
          `}
          className="lfs-shop-with-confidence"
          heading="Shop with confidence"
          data={featureSliderItems}
          responsiveSettings={RESPONSIVE_SETTINGS}
        />

        <Section heading="Policy coverage">
          <Text align={ALIGN.CENTER}>
            Below is a limited summary only. For the full list of benefits and
            the terms, conditions, exclusions, limits, applicable sub-limits and
            excesses that apply, please view the{' '}
            <Link
              href="https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf"
              target="_blank"
            >
              Purchase Protection terms and conditions
            </Link>
            {' and '}
            <Link
              href="https://assets.latitudefinancial.com/legals/28degreescard/ecommerce-protection-insurance-termscond.pdf"
              target="_blank"
            >
              E-Commerce Purchase Protection terms and conditions
            </Link>
            .
          </Text>
          <HowToApply
            box1={
              <>
                <Heading5>Complimentary Purchase Protection Insurance</Heading5>
                <Text>Covers you for:</Text>
                <List>
                  <ListItem>
                    Loss due to <Strong>Accidental Damage or Theft</Strong>,
                    occurring within 180 days from the date of possession of
                    these purchases, up to the benefit limits.
                  </ListItem>
                </List>
                <br />
                <Text>
                  Includes items purchased in physical store, online or given as
                  gifts.
                </Text>
                <br />
                <Text fontWeight={FONT_WEIGHT.BOLD}>Benefit amount (AUD)</Text>
                <List>
                  <ListItem>Per Occurrence Limit: $1,500</ListItem>
                  <ListItem>Annual Total Limit: $1,500</ListItem>
                </List>
                <br />
                <Strong>Excess</Strong>
                <List>
                  <ListItem>Nil</ListItem>
                </List>
                <br />
                <Text fontWeight={FONT_WEIGHT.BOLD}>
                  Exclusions
                  <br />
                  (NOTE this list is not exhaustive, please refer T&amp;Cs for
                  full list and definitions)
                </Text>
                <List>
                  <ListItem>
                    Cash, travellers cheques, negotiable instruments
                  </ListItem>
                  <ListItem>
                    Any motor vehicle, airplanes, drones, boats, automobiles and
                    motorcycles and any equipment, parts or accessories
                  </ListItem>
                  <ListItem>
                    Art, antiques, collectable Items, furs, jewellery, gems,
                    precious stones and fragile items
                  </ListItem>
                  <ListItem>Consumables or perishables</ListItem>
                  <ListItem>
                    Permanent fixtures eg carpeting, air conditioners, heaters,
                    refrigerators
                  </ListItem>
                  <ListItem>Plants or animals</ListItem>
                  <ListItem>
                    Access to internet websites, mobile applications, software
                    or data files downloaded from the internet
                  </ListItem>
                  <ListItem>
                    Services, shipping, handling, installation or assembly costs
                  </ListItem>
                  <ListItem>
                    Loss to the item you purchased online prior to your taking
                    possession of them
                  </ListItem>
                  <ListItem>Items damaged through alteration</ListItem>
                  <ListItem>
                    Items left unattended in a place to which the general public
                    has access
                  </ListItem>
                  <ListItem>Goods purchased for commercial use</ListItem>
                  <ListItem>Used and second hand items</ListItem>
                </List>
              </>
            }
            box2={
              <>
                <Heading5>
                  Complimentary E-Commerce Protection Insurance
                </Heading5>
                <Text fontWeight={FONT_WEIGHT.BOLD}>Covers you for:</Text>
                <List>
                  <ListItem>
                    Non-delivery/and or incomplete delivery of Goods, that are
                    purchased on the internet, if
                    <br />
                    (i) unless otherwise advised by the Seller of a delay, the
                    Goods have not been delivered within 30 days of the
                    scheduled delivery date, and
                    <br />
                    (ii) the Seller has failed to refund You within 60 days of
                    non-delivery.
                  </ListItem>
                  <ListItem>
                    Improper functioning due to physical damage of delivered
                    Goods that are purchased on the internet, provided
                    <br />
                    (i) this resulted from physical damage which was apparent or
                    manifest at time of delivery of the Goods to You; and
                    <br />
                    (ii) the Seller has failed to refund You within 60 days of
                    delivery
                  </ListItem>
                </List>
                <br />
                <Text fontWeight={FONT_WEIGHT.BOLD}>Benefit amount (AUD)</Text>
                <List>
                  <ListItem>Per Occurrence Limit: $1,500</ListItem>
                  <ListItem>Annual Total Limit: $1,500</ListItem>
                </List>
                <br />
                <Text fontWeight={FONT_WEIGHT.BOLD}>Excess</Text>
                <List>
                  <ListItem>Nil</ListItem>
                </List>
                <br />
                <Text fontWeight={FONT_WEIGHT.BOLD}>
                  Exclusions
                  <br />
                  (NOTE this list is not exhaustive, please refer T&Cs for full
                  list and definitions)
                </Text>
                <List>
                  <ListItem>Animals or plant life</ListItem>
                  <ListItem>
                    Cash, negotiable instruments, shares, travellers cheques, or
                    tickets of any description (eg entertainment, sports,
                    travel)
                  </ListItem>
                  <ListItem>Consumable or perishable items</ListItem>
                  <ListItem>Goods purchased for commercial use</ListItem>
                  <ListItem>
                    Access to internet websites, software or data files
                    downloaded off the internet
                  </ListItem>
                  <ListItem>
                    Services provided via the Internet such as cinema tickets,
                    air tickets, hotel bookings, car rental, financial advice
                  </ListItem>
                  <ListItem>Loss of data</ListItem>
                  <ListItem>
                    Goods purchased for resale or items which are used goods,
                    damaged goods or second-hand goods at the time of purchase
                  </ListItem>
                  <ListItem>
                    Permanent household and/or business fixtures
                  </ListItem>
                  <ListItem>
                    Art, antiques, firearms and Collectable Items
                  </ListItem>
                  <ListItem>
                    Furs, watches, jewellery, gems, precious stones and articles
                    made of or containing gold/precious metals
                  </ListItem>
                  <ListItem>Goods deemed to be illegal</ListItem>
                  <ListItem>Used and second hand items</ListItem>
                </List>
              </>
            }
          />
        </Section>
        <Section
          css={`
            && {
              background-color: #fff;
            }
          `}
          heading="How can I make a claim?"
        >
          <Text align={ALIGN.CENTER}>
            You can make an insurance claim{' '}
            <Link
              href="https://au.mycardbenefits.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </Link>{' '}
            or send a notification to obtain a claim form and instructions on
            what to do after a loss.
            <br />
            <br />
            <Strong>The contact details are set out below:</Strong>
            <br />
            AIG Australia Limited
            <br />
            Level 19, 2 Park Street
            <br />
            Sydney, NSW 2000 Australia
            <br />
            Tel: +61395224111
            <br />
            <br />
            <Strong>Customer Service Operating Hours:</Strong> Monday to Friday
            8:30am to 5:30pm AEST
            <br />
            Email:{' '}
            <a href="mailto:APAC.Mastercard@aig.com">APAC.Mastercard@aig.com</a>
            <br />
            <br />
            To find out more, please refer to the complimentary{' '}
            <Link
              href="https://assets.latitudefinancial.com/legals/28degreescard/ecommerce-protection-insurance-termscond.pdf"
              target="_blank"
            >
              E-Commerce Purchase Protection terms and conditions
            </Link>
            {' and '}
            <Link
              href="https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf"
              target="_blank"
            >
              Purchase Protection terms and conditions
            </Link>
            .
          </Text>
        </Section>

        <div
          css={`
            && div.lfs-other-features {
              padding-bottom: 0;
            }
            && div.lfs-card-icon {
              background-color: #fff;
              width: 100px !important;
              height: auto !important;
              padding: 10px;
              border-radius: 50px;
            }
          `}
        >
          <FeaturesSlider
            className="lfs-other-features"
            backgroundColor="#F8F8F8"
            heading="Don't forget all the other amazing features to enjoy!"
            data={DATA1}
            responsiveSettings={RESPONSIVE_SETTINGS}
          />
          <FeaturesSlider
            className="lfs-other-features"
            backgroundColor="#F8F8F8"
            data={DATA2}
            responsiveSettings={RESPONSIVE_SETTINGS}
          />
        </div>

        <DontHaveACardSection anchor="dont-have-card" />

        <BoxQuestion>
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            css="margin-bottom:8px;"
          >
            Have a question?
          </Heading4>
          <Text
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            css="margin-bottom:24px;"
          >
            Contact AIG on the details above or check our FAQs page to get
            answers.
          </Text>
          <Link
            button={BUTTON_STYLE.TERTIARY}
            href="/credit-cards/28-degrees/faqs/"
            trackId="have-a-question-banner__find-out-more-button"
            disableDefaultEventTracking
            css={`
          display: inline-block;
          svg { 
            position: relative;
            bottom: 2px;
            width: 35px;
          }
          &:hover{
            svg *{
              fill #fff !imporatant;
            }
          }
        `}
          >
            Explore FAQ
            <SvgInline name="external-link" />
          </Link>
        </BoxQuestion>

        <ImportantInformation
          id="important-Info"
          data={{
            content: {
              importantInformationSectionOne: [
                "<sup id='note-1'>1</sup>&nbsp;Latitude 28&deg; Global Platinum Mastercard cardholders will be eligible for a $10 Latitude Reward every statement period which is redeemable for an e-gift card from participating retailers when they spend $1,000 or more on eligible transactions using their credit card within the statement period. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default of the credit contract when the Latitude Rewards is issued. The Latitude Rewards will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days from the end of the statement period. The primary cardholder has up to 18 months to redeem from the date of issue. View <a href='https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf' target='_blank'>full terms and conditions</a>.",
                "<sup id='note-2'>2</sup>&nbsp;Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary Purchase Protection Insurance are the current holders of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard credit card Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. View <a href='https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf' target='_blank'>full terms and conditions</a>."
              ],
              importantInformationSectionTwo: [
                "<sup id='note-3'>3</sup>&nbsp;E-Commerce Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary E-Commerce Purchase Protection Insurance are the current holder of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these e-commerce purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard credit card E-Commerce Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. View <a href='https://assets.latitudefinancial.com/legals/28degreescard/ecommerce-protection-insurance-termscond.pdf' target='_blank'>full terms and conditions</a>.",
                "<sup id='note-4'>4</sup>&nbsp;The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.",
                'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        />
      </main>
    </Layout>
  );
};

const BoxQuestion = styled(Box)`
  padding: 40px 0;
  background-color: #e8f4ff;
  background-image: url(${calloutLeftBg}), url(${calloutRightBg});
  background-position: left top, right 122%;
  background-repeat: no-repeat, no-repeat;
  text-align: center;
  [class*='Linkstyled__StyledLink']:hover {
    svg * {
      fill: #fff !important;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 72px 0;
  }
`;

export default InsurancesPage;
